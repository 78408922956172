const isDevProd = false

const URLs = {
    baseURL: process.env.NODE_ENV === 'production' ? '/api' : (isDevProd ? 'https://royalcasino.ua/api' : 'https://dev.royalcasino.ua/api'),
    // baseURL: 'https://royalcasino.ua/api',
    // baseURL: 'https://dev.royalcasino.ua/api',
    spammerServiceUrl: 'https://bt.royalcasino.ua'
    // spammerServiceUrl: 'https://3ad5-195-246-217-253.ngrok-free.app'


}

export default URLs;
